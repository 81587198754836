.shop-container{
    display: grid;
    grid-template-columns: 4fr 1fr;
}
.products-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 100px;
}

.cart-container{
    background-color: rgba(255, 224, 179, 1);
}

