.product{
    margin-left: 30px; 
    width: 300px;
    height: 510px;
    border: 1px solid #95a0a7;
    border-radius: 8px;
    position: relative;
}
.product img{
    width: 286px;
    height: 286px;
    border-radius: 8px;
    margin: 8px;
}
.product-info{
    margin: 0 14px;
}
.product-info p{
    margin-bottom: 0;
    margin-top: 10px;
}
.product-name{
    font-family: 'lato';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    margin-top: 7px;
}
.cart-btn{
    width: 100%;
    background-color: #FFE0B3;
    border: none;
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-btn p{
    padding-right: 5px;
}
.cart-btn:hover{
    background-color: #FF9900;
}