.review-item {
    border: 1px solid #95a0a7;
    border-radius: 8px;
    padding: 8px;
    margin-top: 21px;
    width: 571px;
    display: flex;
}

.review-item img {
    width: 90px;
    border-radius: 5px;
}

.detail-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
}

.review-item-detail p {
    margin: 0;
}

.delete-container {
    display: flex;
    align-items: center;
}

.delete-icon {
    color: rgba(235, 87, 87, 1);
    font-size: 27px;
}

.delete-button {
    background: rgba(235, 87, 87, 0.3);
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    cursor: pointer;
}