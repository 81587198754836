.cart{
    background-color: rgba(255, 224, 179, 1);
    position: sticky;
    top: 0;
    padding: 30px 20px;
} 
.cart h4{
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
}