.form-container {
    margin: 0 auto;
    width: 600px;
    border: 1px solid #95A0A7;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.form-title {
    font-size: 35px;
    font-weight: 400;
    color: #2a414f;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    margin-left: 5px;
    margin-bottom: 5px;
}

.input-group input {
    width: 400px;
    height: 40px;
    border: 1px solid #95A0A7;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 20px;
}

.form-submit {
    width: 400px;
    height: 40px;
    background-color: rgba(255, 153, 0, 0.3);
    border-right: 5px;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.form-submit:hover {
    background-color: rgba(255, 153, 0, 0.5);
}

.form-link {
    color: #FF9900;
    text-decoration: none;
}